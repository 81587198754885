import { FC, useState, useEffect, useContext } from 'react';
import { IVehicleTile } from '../../../types/vehicleTile';
import * as savedVehicles from '../utils/savedVehicles';
import { FBSContext } from '../../../context';
import ReturnVisitorCarousel from '../../../components/carousels/home-base-hero-carousel/ReturnVisitorCarousel';
import { useLocalCarsStore } from '../../../context/localCarsStore';
import { useShallow } from 'zustand/react/shallow';

type MiniHomebaseProps = {
    viewedVehicleTileList: IVehicleTile[];
    updatedViewedTiles: (vehicleTile: IVehicleTile) => void;
    onOpenSnackBar: any;
};

export const MiniHomebase: FC<MiniHomebaseProps> = ({ viewedVehicleTileList, updatedViewedTiles, onOpenSnackBar }) => {
    const [favoritedStockNumberList, setFavoritedStockNumberList] = useState<number[]>([]);
    const [stockNumbersPendingFavoritesUpdate, setStockNumbersPendingFavoritesUpdate] = useState<number[]>([]);

    const fbs = useContext(FBSContext);

    const { favoriteVehicles } = useLocalCarsStore(
        useShallow((state) => ({
            favoriteVehicles: state.favoriteVehicles,
        }))
    );

    useEffect(() => {
        setFavoritedStockNumberList(favoriteVehicles);
    }, [favoriteVehicles]);

    return (
        <div className="buying-online-mini-homebase">
            <h2 className="kmx-typography--display-4 buying-online-mini-homebase--heading">
                Pick up where you left off
            </h2>
            <ReturnVisitorCarousel
                id="kmx-viewed-carousel"
                isMiniHomebase={true}
                carouselName="mini-homebase"
                analyticsName="MiniHomebase"
                isVisible={true}
                vehicleTileList={viewedVehicleTileList}
                favoritedStockNumberList={favoritedStockNumberList}
                stockNumbersPendingFavoritesUpdate={stockNumbersPendingFavoritesUpdate}
                updatedViewedTiles={updatedViewedTiles}
                enableRemove={true}
                fbsData={fbs.fbsData}
                onOpenSnackBar={onOpenSnackBar}
            />
        </div>
    );
};
