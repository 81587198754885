export interface IEventChannelData {
    [key: string]: any;
}

export interface IEventChannelListener {
    (data: IEventChannelData | any): any;
}

export interface IEventChannelRemoveListenerObj {
    remove: () => any;
}

interface IEventChannelTopics {
    [key: string]: IEventChannelListener[];
}

export interface IEventChannel {
    topics: IEventChannelTopics;
    subscribe: (topic: string, listener: IEventChannelListener) => IEventChannelRemoveListenerObj;
    publish: (topic: string, data: {}) => any;
}

export default class EventChannel implements IEventChannel {
    private _topics: IEventChannelTopics;

    constructor() {
        this._topics = {};
    }

    private hasTopic(topic: string): boolean {
        return this._topics.hasOwnProperty.call(this._topics, topic);
    }

    get topics(): IEventChannelTopics {
        return this._topics;
    }

    subscribe(topic: string, listener: IEventChannelListener): IEventChannelRemoveListenerObj {
        if (!this.hasTopic(topic)) {
            this._topics[topic] = [];
        }

        const index = this._topics[topic].push(listener) - 1;

        return {
            remove: () => {
                delete this._topics[topic][index];
            },
        };
    }

    publish(topic: string, data = {}) {
        if (!this.hasTopic(topic)) {
            return;
        }

        this._topics[topic].forEach((listener: IEventChannelListener) => {
            listener(data);
        });
    }
}
