import * as React from 'react';
import { gsap, TimelineMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface IHeroProps {}
interface IHeroState {}

class Hero extends React.Component<IHeroProps, IHeroState> {
    constructor(props: any) {
        super(props);
        this.state = {};

        gsap.registerPlugin(ScrollTrigger as any);
    }

    private initCarAnimation() {
        const carLeaveScene = new TimelineMax({ paused: true });
        carLeaveScene.from('.buying-online-hero--car-image', { x: '350px', duration: 0 });
        carLeaveScene.to('.buying-online-hero--car-image', {
            x: `-${window.innerWidth}px`,
            ease: 'Power3.in',
            duration: 4
        });

        (ScrollTrigger as any).create({
            trigger: '.buying-online-hero--car-image',
            start: 'top top',
            animation: carLeaveScene,
            invalidateOnRefresh: true
        });

        let xPos = 280;
        if (window.innerWidth < 700) {
            xPos = 280;
        } else if (window.innerWidth < 900) {
            xPos = 220;
        }
        gsap.to('.buying-online-hero--car-image', { x: `${xPos}px`, duration: 2, ease: 'Power3.out', delay: 0.25 });
    }

    private initFixedHeading() {
        function setPosition(val: string) {
            const topVal = val === 'absolute' ? '100vh' : 'unset';
            gsap.set('.buying-online-hero--heading-fixed-container', { position: val, top: topVal });
        }

        (ScrollTrigger as any).create({
            trigger: '.buying-online-hero--container',
            start: 'bottom-=300 top',
            onEnter: () => {
                setPosition('absolute');
            },
            onLeaveBack: () => {
                setPosition('fixed');
            }
        });
    }

    public componentDidMount() {
        this.initCarAnimation();
        this.initFixedHeading();
    }

    public render(): React.ReactNode {
        return (
            <div className="buying-online-hero">
                <div className="buying-online-hero--desktop-flex-container">
                    <div className="buying-online-hero--heading-container">
                        <div className="buying-online-hero--heading-fixed-container">
                            <h1
                                id="main-content-heading"
                                className="kmx-typography--display-6 buying-online-hero--heading-copy"
                            >
                                Find the one, buy it online
                            </h1>
                        </div>
                    </div>
                    <div className="buying-online-hero--image-container">
                        <div className="buying-online-hero--image-flex-container">
                            <img
                                className="buying-online-hero--phone-image"
                                src="/home/images/buying-online/hero/buyonline-hero-phone.png"
                                alt=""
                            />
                            <div className="buying-online-hero--clipping-mask-container">
                                <img
                                    className="buying-online-hero--car-image"
                                    src="/home/images/buying-online/hero/buyonline-hero-car.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero;
