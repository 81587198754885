import { FC } from 'react';

export const MiniHomebasePusher: FC = () => {
    return (
        <div className="buying-online-mini-homebase-pusher">
            <div className="buying-online-mini-homebase-pusher--flex-container">
                <h2 className="kmx-typography--display-6 buying-online-mini-homebase-pusher--heading">
                    Ready to find the one?
                </h2>
                <a
                    id="mini-homebase-cta"
                    href="/cars/all"
                    className="kmx-button buying-online-mini-homebase-pusher--cta"
                >
                    Start a Search
                </a>
            </div>
        </div>
    );
};
