import * as React from 'react';
import EventChannel from '../../../events/EventChannel';
import { CUSTOM_EVENTS, TOPICS } from '../../../events/constants';
import { Store } from '../../../types/store';
import { getFaqSections } from '../../../api/faq-content-service-api';
import { FaqSection, FaqItem } from '../../../api/types/faq-content-api';
import { QuestionLink } from '../../faq/faq/components/QuestionLink/QuestionLink';

const FAQ = () => {
    const [nearestStoreId, setNearestStoreId] = React.useState<string>();
    const [buyOnlineFaqContent, setBuyOnlineFaqContent] = React.useState<FaqSection>();

    const faqSectionTitle = 'Buying a Car';

    const attachNearestStoreChangeEventHandlers = () => {
        const eventChannel = new EventChannel();
        const myStoreModuleUpdatesEvent = new CustomEvent(CUSTOM_EVENTS.MY_STORE_MODULE_UPDATES, {
            detail: {
                done: (store: Store) => eventChannel.publish(TOPICS.MY_STORE_MODULE_UPDATES, store),
            },
        });

        const handleEvent = (store: Store) => {
            if (store && store.Id && store.Id !== nearestStoreId) {
                setNearestStoreId(store.Id);
            }
        };

        eventChannel.subscribe(TOPICS.MY_STORE_MODULE_UPDATES, handleEvent);

        document.documentElement.dispatchEvent(myStoreModuleUpdatesEvent);
    };
    attachNearestStoreChangeEventHandlers();

    React.useEffect(() => {
        getFaqSections().then((res) => {
            const faqSections = res.filter((item) => {
                return item.sectionTitle === faqSectionTitle;
            });
            setBuyOnlineFaqContent(faqSections[0]);
        });
    }, []);

    return buyOnlineFaqContent ? (
        <div className="buying-online-faq">
            <div className="buying-online-faq--heading-container">
                <h2 className="kmx-typography--display-3 buying-online-faq--heading">Frequently Asked Questions</h2>
                <div className="buying-online-faq--desktop-button-container">
                    <a
                        href="/help-center#contact-us"
                        className="kmx-button kmx-button--tertiary buying-online-faq--transparent-button buying-online-faq--desktop-button"
                    >
                        Need more help?
                    </a>
                </div>
            </div>
            <div className="buying-online-faq--content-container">
                {buyOnlineFaqContent.faqs.map((faq: FaqItem, index: number) => {
                    return <QuestionLink key={index} text={faq.question} url={faq.url} />;
                })}
                <div className="buying-online-faq--mobile-button-container">
                    <a
                        href="/help-center#contact-us"
                        className="kmx-button kmx-button--tertiary buying-online-faq--transparent-button buying-online-faq--mobile-button"
                    >
                        Need more help?
                    </a>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default FAQ;
