import * as React from 'react';
import * as ReactDOM from 'react-dom';
import onDomContentLoaded from '../../utilities/onDomContentLoaded';
import ErrorBoundary from '../../components/ErrorBoundary';
import PageHeader from './components/PageHeader';
import Hero from './components/Hero';
import Steps from './components/Steps';
import LoveItOrReturnIt from './components/LoveItOrReturnIt';
import FAQ from './components/FAQ';
import { MiniHomebaseContainer } from './components/MiniHomebaseContainer';
import '../../../scss/pages/buying-online/main.scss';
import { FBSProvider } from '../../context';
import ModalHandler from '@kmx/shared-components/modalverse/ModalHandler';

function startup() {
    const stickyHeaderContainer = document.getElementById('buying-online-page-header--container');
    if (stickyHeaderContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <PageHeader />
            </ErrorBoundary>,
            stickyHeaderContainer
        );
    }

    const heroContainer = document.getElementById('buying-online-hero--container');
    if (heroContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <Hero />
            </ErrorBoundary>,
            heroContainer
        );
    }

    const stepsContainer = document.getElementById('buying-online-steps--container');
    if (stepsContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <Steps />
            </ErrorBoundary>,
            stepsContainer
        );
    }

    const loveItContainer = document.getElementById('buying-online-love-it--container');
    if (loveItContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <LoveItOrReturnIt />
            </ErrorBoundary>,
            loveItContainer
        );
    }

    const FAQContainer = document.getElementById('buying-online-faq--container');
    if (FAQContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <FAQ />
            </ErrorBoundary>,
            FAQContainer
        );
    }

    const miniHomebaseContainer = document.getElementById('buying-online-mini-homebase--container');
    if (miniHomebaseContainer) {
        ReactDOM.render(
            <ErrorBoundary>
                <ModalHandler>
                    <FBSProvider>
                        <MiniHomebaseContainer />
                    </FBSProvider>
                </ModalHandler>
            </ErrorBoundary>,
            miniHomebaseContainer
        );
    }
}

onDomContentLoaded(() => {
    startup();
});
