import { FaqSection } from './types/faq-content-api';

const emptySections: FaqSection[] | PromiseLike<FaqSection[]> = [];

export async function getFaqSections(): Promise<FaqSection[]> {
    try {
        const url = `/home/api/content/faq-section`;
        const res = await fetch(url);
        return await res.json();
    } catch (error) {
        return Promise.resolve(emptySections);
    }
}
