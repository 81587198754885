import { getVehicles } from '../../../api/search-api';
import { Vehicle } from '../../../api/types';

export const getStockNumberFromCarDetailsReferrer = () => {
    if(document.referrer) {
        const urlParts = document.referrer.split('/');
        const indexOfStockNumber = urlParts.indexOf('car') > -1 ? (urlParts.indexOf('car') + 1) : -1;
        if(indexOfStockNumber > -1) {
            const sanitizedStockNumber = urlParts[indexOfStockNumber].indexOf('?') > -1 ? urlParts[indexOfStockNumber].split('?')[0] : urlParts[indexOfStockNumber];
            return [parseInt(sanitizedStockNumber)];
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export const getVehicleInfoFromCarDetailsReferrer = () => {
    const referrerStockNumber = getStockNumberFromCarDetailsReferrer();
    if(referrerStockNumber) {
        return getVehicles({
            stockNumberList: referrerStockNumber,
        }).then(function(vehiclesList: Vehicle[]) {
            if (vehiclesList.length > 0) {
                return vehiclesList;
            } else {
                return []
            }
        } as any);
    } else {
        return Promise.resolve([]);
    }
}

export const buildVehicleLabel = (vehicleInfo: any, vehiclePropertiesToExtract: Array<string>) => {
    let finalizedLabel = '';
    for (let i = 0; i < vehiclePropertiesToExtract.length; i++) {
        const singleVehicleProperty = vehiclePropertiesToExtract[i];
        if(vehicleInfo[singleVehicleProperty]) {
            finalizedLabel += ` ${vehicleInfo[vehiclePropertiesToExtract[i]]}`;
        }
    }
    return finalizedLabel;
}