import * as React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SVGs from '../utils/svgs';
import { getVehicleInfoFromCarDetailsReferrer, buildVehicleLabel } from '../utils/utils';
import { Vehicle } from '../../../api/types';

interface IPageHeaderProps { }
interface IPageHeaderState {
    vehicleInfo: Vehicle[];
    vehicleLabel: string;
}

const VEHICLE_ATTRIBUTES = ['make', 'model', 'trim'];

class PageHeader extends React.Component<IPageHeaderProps, IPageHeaderState> {
    constructor(props: any) {
        super(props);
        this.state = {
            vehicleInfo: [],
            vehicleLabel: ''
        };

        gsap.registerPlugin(ScrollTrigger as any);
    }

    private setVehicleInfoFromPageReferrer() {
        getVehicleInfoFromCarDetailsReferrer().then((vehicleInfo: Vehicle[]) => {
            this.setState({
                vehicleInfo: vehicleInfo,
                vehicleLabel: vehicleInfo.length ? buildVehicleLabel(vehicleInfo[0], VEHICLE_ATTRIBUTES) : ''
            });
        });
    }

    private initFixedHeaderOnScroll() {
        function toggleHeaderVisibility(visible: boolean) {
            gsap.set('.buying-online-page-header--container', { y: visible ? 64 : 0 });
        }

        (ScrollTrigger as any).create({
            trigger: '#main',
            start: 'top top',
            endTrigger: '#footer',
            end: 'bottom top',
            onEnter: () => {
                toggleHeaderVisibility(true);
            },
            onLeaveBack: () => {
                toggleHeaderVisibility(false);
            },
            invalidateOnRefresh: true
        });
    }

    public componentDidMount() {
        this.setVehicleInfoFromPageReferrer();
        this.initFixedHeaderOnScroll();
    }

    public render(): React.ReactNode {
        return (
            <div className="buying-online-page-header">
                {this.state.vehicleLabel.length ? (
                    <div className="buying-online-page-header--back">
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                window.history.back();
                            }}
                            className="kmx-button kmx-button--tertiary buying-online-page-header--back-button"
                        >
                            <span className="buying-online-page-header--back-arrow">{SVGs.arrowIcon}</span>
                            <span className="buying-online-page-header--back-label">
                                Back{' '}
                                <span className="buying-online-page-header--back-label__desktop">
                                    to {this.state.vehicleLabel}
                                </span>
                            </span>
                        </a>
                    </div>
                ) : (
                    <h1 className="kmx-typography--display-2 buying-online-page-header--heading">Buying Online</h1>
                )}
                <div className="buying-online-page-header--cta">
                    <a
                        id="page-header-cta"
                        href="/cars/all"
                        className="kmx-button kmx-button--primary buying-online-page-header--cta-button"
                        data-kmx-analytics="searchInit"
                    >
                        Find a Car
                    </a>
                </div>
            </div>
        );
    }
}

export default PageHeader;
