import * as React from 'react';
import SVGs from '../utils/svgs';
import { smoothScroll } from '../../../utilities/smoothScroll';
import { gsap, TimelineMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface ILoveItOrReturnItProps {}
interface ILoveItOrReturnItState {}

const LABEL_OF_LINKED_FAQ_ITEM = 'Do I really get 30 days to return the car?';
const FAQ_HIGHLIGHT_SELECTOR = 'buying-online-faq--faq-item__highlight';

class LoveItOrReturnIt extends React.Component<ILoveItOrReturnItProps, ILoveItOrReturnItState> {
    constructor(props: any) {
        super(props);
        this.state = {};

        this.linkToAndOpenFAQItem = this.linkToAndOpenFAQItem.bind(this);

        gsap.registerPlugin(ScrollTrigger as any);
    }

    private removeHighlightFromFAQItem(faqItemToLinkTo: HTMLElement) {
        faqItemToLinkTo.classList.remove(FAQ_HIGHLIGHT_SELECTOR);
    }

    private highlightFAQItem(faqItemToLinkTo: HTMLElement) {
        faqItemToLinkTo.classList.add(FAQ_HIGHLIGHT_SELECTOR);
    }

    private linkToAndOpenFAQItem(e: any) {
        e.preventDefault();
        const faqItemToLinkTo: HTMLElement | null = document.querySelector(
            `button[aria-label="${LABEL_OF_LINKED_FAQ_ITEM}"]`
        );
        if (faqItemToLinkTo) {
            smoothScroll(faqItemToLinkTo as HTMLElement, -80);
            this.removeHighlightFromFAQItem(faqItemToLinkTo as HTMLElement);
            setTimeout(() => {
                if ((faqItemToLinkTo as HTMLElement).getAttribute('aria-expanded') == 'false') {
                    (faqItemToLinkTo as HTMLElement).click();
                }
                this.highlightFAQItem(faqItemToLinkTo as HTMLElement);
            }, 1000);
        }
    }

    private initScrollAnimations() {
        const tl = new TimelineMax();
        tl.to(
            '.buying-online-love-it--bordered-overlay',
            {
                'border-left-width': 0,
                'border-right-width': 0,
                'border-top-width': 0,
                'border-bottom-width': 0,
                ease: 'Power3.easeOut',
                duration: 3,
            },
            '-=3'
        );
        tl.from(
            '.buying-online-love-it--darkened-overlay',
            { autoAlpha: 0, ease: 'Power3.easeOut', duration: 3 },
            '-=3'
        );
        tl.from(
            '.buying-online-love-it--content-container',
            { opacity: 0, y: 250, ease: 'Power3.easeOut', duration: 3 },
            '-=2'
        );

        (ScrollTrigger as any).create({
            trigger: '#buying-online-love-it--container',
            start: 'top-=64 top',
            end: `bottom-=128 top`,
            pin: true,
            invalidateOnRefresh: true,
        });

        (ScrollTrigger as any).create({
            trigger: '#buying-online-love-it--container',
            start: 'top-=244 top',
            scrub: true,
            animation: tl,
        });
    }

    public componentDidMount() {
        this.initScrollAnimations();
    }

    public render(): React.ReactNode {
        return (
            <div className="buying-online-love-it">
                <div className="buying-online-love-it--darkened-overlay" />
                <div className="buying-online-love-it--bordered-overlay" />
                <div className="buying-online-love-it--max-width-container">
                    <div className="buying-online-love-it--content-container">
                        <h2 className="kmx-typography--display-6 buying-online-love-it--heading">
                            The way <br /> car buying <br /> should be
                        </h2>
                        <p className="kmx-typography--body-2 buying-online-love-it--copy">
                            Upfront prices. CarMax Certified quality.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoveItOrReturnIt;
